import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStylemoreWidth } from 'src/utils/cssStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Heading1 } from 'src/components/headings/Heading';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { useGetAvailableData } from './useGetAvailableData';

export default function ShowBagListModal({
  open,
  setOpen,
  availableBags,
  manifestId,
  bags,
  setBags,
  BagManifest,
  setBagManifest,
  bag,
  setBag,
  setManifestName,
}) {
  const [dropStatus, seDropStatus] = useState(false);

  let Manifest = bags?.filter((data) => data.status === 'Manifest');
  let Bag = bags?.filter((data) => data.status === 'Bag');
  const savedbag = useSelector((state) => state.bag.savedBag);
  const { getSingleManifestData, fetchAvaialbleBags } = useGetAvailableData();
  // console.log(bags);

  useEffect(() => {
    // const findCat = bags?.filter((item) => item?.EquipmentCategoryName === 'bags');
    // if (findCat) {
    setBagManifest(bags?.filter((data) => data.status === 'Manifest'));
    setBag(bags?.filter((data) => data.status === 'Bag'));
    // }
  }, [bags]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
    getSingleManifestData(manifestId);
    fetchAvaialbleBags();
  };

  useEffect(() => {
    setBags(availableBags);
  }, [availableBags]); 

  const onDragStart = (evt, bagId) => {
    let element = evt.currentTarget;
    element.classList.add('dragged');
    evt.dataTransfer.setData('text/plain', bagId); 
    evt.dataTransfer.effectAllowed = 'move';
  };
  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove('dragged');
  };

  const onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add('dragged-over');
    evt.dataTransfer.dropEffect = 'move';
  };

  const onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget) return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove('dragged-over');
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove('dragged-over');
    let data = evt.dataTransfer.getData('text/plain');

    let newBagData = [...bags];
    const findIndex = newBagData.findIndex((item) => item.BagId === data);
    if (typeof newBagData[findIndex] !== 'undefined') {
      newBagData[findIndex] = {
        ...newBagData[findIndex],
        status: newBagData[findIndex].status === 'Bag' ? 'Manifest' : 'Bag',
      };
    }
    setBags(newBagData);
    setTimeout(() => {
      seDropStatus(true);
    }, 300);
    seDropStatus(false);

    // RunAddmanifestApi(data);
  };
  // console.log(bags);
  // console.log(bags?.filter((data) => data.status === 'Manifest'));

  useEffect(() => {
    if (dropStatus === true) {
      RunAddmanifestApi();
    }
  }, [dropStatus]); 

  function extractAndFormatBagNames(bagArray) {
    if (bagArray.length === 0) return '';

    let prefix = '';
    let numbers = [];

    // Loop through the bagArray to extract all numbers
    for (let i = 0; i < bagArray.length; i++) {
        let match = bagArray[i].BagName.match(/^SKN(\d+)/);
        if (match) {
            // If the BagName starts with "SKN", extract the number part for the prefix
            prefix = match[0]; // e.g., "SKN480"
            numbers.push(parseInt(match[1], 10)); // Extract the number part (e.g., 480)
        } else {
            // If "SKN" is not found, extract any numbers in the BagName
            let numberMatch = bagArray[i].BagName.match(/\d+/);
            if (numberMatch) {
                numbers.push(parseInt(numberMatch[0], 10));
            }
        }
    }

    // Sort all the numbers in ascending order
    numbers.sort((a, b) => a - b);


    return 'SKN' + numbers.join(',');
}


  const RunAddmanifestApi = async (id) => {
    await axios.post(`${ApiUrl2}api/Manifest/UpdateManifestBagList?manifestId={${manifestId}}`, Manifest);
    const data=extractAndFormatBagNames(Manifest)
    setManifestName(data);
  };

  // console.log(Manifest);
  useEffect(() => {
    availableBags && savedbag && setBags(availableBags.concat(savedbag));
  }, [availableBags, savedbag]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(savedbag);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStylemoreWidth, height: '90%' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CancelIcon />
          </IconButton>
          <Grid container spacing={2} sx={{ height: '95%' }}>
            {/* Left Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Heading1 title="Available Bag List" />
              <section
                className="drag_container"
                style={{ flex: 1, overflowY: 'auto', height: '100%', marginTop:5 }}
                onDragLeave={(e) => onDragLeave(e)}
                onDragEnter={(e) => onDragEnter(e)}
                onDragEnd={(e) => onDragEnd(e)}
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e, false, 'equipment')}
              >
                <div className="drag_column">
                  <div className="drag_row">
                    {bag?.map((task, i) => (
                      <div
                        className="card my-2"
                        key={i}
                        id={task.id}
                        draggable
                        onDragStart={(e) => onDragStart(e, task.BagId)}
                        onDragEnd={(e) => onDragEnd(e)}
                      >
                        <Grid container flexDirection="column" padding={1}>
                          <Typography fontWeight={700} color="#007C7C">
                            Bag Name: {task.BagName}
                          </Typography>
                          <Typography>Bag Description: {task.BagDescription || 'NA'}</Typography>
                        </Grid>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </Grid>

            {/* Right Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', flexDirection: 'column', height: '95%' }}
            >
              <Heading1 title="Add to RopeKit List" />
              <section
                style={{ flex: 1, overflowY: 'auto', height: '100%', marginTop:5 }}
                onDragLeave={(e) => onDragLeave(e)}
                onDragEnter={(e) => onDragEnter(e)}
                onDragEnd={(e) => onDragEnd(e)}
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e, true, 'Manifest')}
              >
                <div className="drag_column">
                  <div className="drag_row">
                    {BagManifest?.map((task, i) => (
                      <div
                        className="card my-2"
                        key={i}
                        id={task.id}
                        draggable
                        onDragStart={(e) => onDragStart(e, task.BagId)}
                        onDragEnd={(e) => onDragEnd(e)}
                      >
                        <Grid container flexDirection="column" padding={1}>
                          <Typography fontWeight={700} color="#007C7C">
                            Bag Name: {task.BagName}
                          </Typography>
                          <Typography>Bag Description: {task.BagDescription || 'NA'}</Typography>
                        </Grid>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
