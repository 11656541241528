import React from 'react';
import { useGetAuditList } from './useGetAvailableData';
import { Box, IconButton, Modal } from '@mui/material';
import { modalStyle } from 'src/utils/cssStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Typography from 'src/theme/overrides/Typography';

export default function ShowAuditModal({ open, setOpen, editData }) {
  const { GetAuditDataList, auditData } = useGetAuditList();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadExcel = () => {
    if (!auditData || !auditData.length) {
      console.error('No data to export');
      return;
    }

    // Extracting only the required fields
    const filteredData = auditData.map(({ ManifestName, AuditDate, AuditMessage, AuditFileName }) => ({
      'RopeKit Name': ManifestName,
      'Audit Date': AuditDate,
      'Audit Message': AuditMessage,
    }));
    const topManifestId = auditData[0].ManifestName;

    // Creating a worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);

    const columnWidths = [
      { wch: 40 }, // Width of the first column (ManifestId)
      { wch: 20 }, // Width of the second column (AuditDate)
      { wch: 60 }, // Width of the third column (AuditMessage)
    ];

    // Applying custom column widths
    ws['!cols'] = columnWidths;

    // Adding a heading row
    // Creating a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'AuditData');

    // Saving the workbook as an Excel file
    XLSX.writeFile(wb, `AuditData_${topManifestId}.xlsx`);
  };

  const handleDownloadAuditData = (rowData) => {
    fetch(`${ApiUrl2}api/Manifest/DownloadManifestAuditFile?manifestId=${rowData.ManifestId}&fileId=${rowData.Id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentDisposition = response.headers.get('content-disposition');
        const filename = `RopeKit_${rowData.ManifestName}.pdf`;

        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };

  useEffect(() => {
    if (open === true) {
      GetAuditDataList({ editData });
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="d-flex justify-content-between mb-4" >
            <h4 >View Audit History</h4>
            <IconButton aria-label="delete" className="coss_icon_export" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="d-flex justify-content-center mb-3">
            {auditData && auditData.length > 0 && (
              <PrimaryButton title="Download All Audit Data" onClick={handleDownloadExcel} />
            )}
          </div>
          <br></br>

          {auditData && typeof auditData === 'object' ? (
            <>
              {auditData.length === 0 ? (
                <div
                  className="my-4 d-flex align-items-center justify-content-center"
                  style={{ border: '2px solid var(--500)', minHeight: '150px', borderRadius: '8px' }}
                >
                  No data available
                </div>
              ) : (
                <>
                  <div className="  d-flex align-items-center justify-content-between my-2">
                    <div className="container">
                      <div className="row">
                        <div className=" col-sm-5 d-flex align-items-center">Message</div>
                        <div className=" col-sm-3 d-flex align-items-center">
                          <span> Date</span>
                        </div>
                        <div className=" col-sm-4 d-flex align-items-center">Action</div>
                      </div>
                    </div>
                  </div>
                  {auditData.map((data, i) => (
                    <div className="  d-flex align-items-center justify-content-between my-2" key={i}>
                      <div className="container">
                        <div className="row">
                          <div className=" col-sm-5 d-flex align-items-center">{data.AuditMessage}</div>
                          <div className=" col-sm-3 d-flex align-items-center">
                            <span> {data.AuditDate}</span>
                          </div>
                          <div className=" col-sm-4 d-flex align-items-center">
                            <SecondaryButton
                              variant="contained"
                              color="primary"
                              onClick={() => handleDownloadAuditData(data)}
                              title="Download Audit Data"
                            >
                              Download
                            </SecondaryButton>
                          </div>
                          <hr style={{ margin: '5px 10px', height: '2px', border: 'none', backgroundColor: '#ccc' }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
}
