import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStylemoreWidth } from 'src/utils/cssStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Heading1 } from 'src/components/headings/Heading';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { useGetAvailableData } from './useGetAvailableData';

export default function ShowListmodal({
  open,
  setOpen,
  availableEquipment,
  manifestId,
  setEquipments,
  equipments,
  equipmentCategoryDatas,
  equipmentCategory,
  Manifest,
  setManifest,
  equipment,
  setequipment,
}) {
  const [dropStatus, seDropStatus] = useState(false);
  const { getSingleManifestData, fetchAvaialbleEquipments } = useGetAvailableData();
  
  useEffect(() => {
    const findCat = equipments?.filter((item) => item?.EquipmentCategoryName === equipmentCategory);
    if (findCat) {
      setManifest(findCat?.filter((data) => data.status === 'Manifest'));
      setequipment(findCat?.filter((data) => data.status === 'Equipment'));
    }
  }, [equipments, equipmentCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const savedEquipment = useSelector((state) => state.equipment.savedEquipment);
  const handleClose = () => {
    setOpen(false);
    getSingleManifestData(manifestId);
    fetchAvaialbleEquipments();
  };

  const onDragStart = (evt, bagId) => {
    let element = evt.currentTarget;
    element.classList.add('dragged');
    evt.dataTransfer.setData('text/plain', bagId); // Set the bagId as data
    evt.dataTransfer.effectAllowed = 'move';
  };
  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove('dragged');
  };

  const onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add('dragged-over');
    evt.dataTransfer.dropEffect = 'move';
  };

  const onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget) return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove('dragged-over');
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove('dragged-over');
    let data = evt.dataTransfer.getData('text/plain');
    let newEquipmentData = [...equipments];
    const findIndex = newEquipmentData.findIndex((item) => item.EquipmentId === data);
    if (typeof newEquipmentData[findIndex] !== 'undefined') {
      newEquipmentData[findIndex] = {
        ...newEquipmentData[findIndex],
        status: newEquipmentData[findIndex].status === 'Equipment' ? 'Manifest' : 'Equipment',
      };
    }

    // dispatch({ type: updateSavedEquipment, payload: newEquipmentData[findIndex] });
    setEquipments(newEquipmentData);
    setTimeout(() => {
      seDropStatus(true);
    }, 300);
    seDropStatus(false);

    // RunAddmanifestApi(data);
  };

  useEffect(() => {
    if (dropStatus === true) {
      RunAddmanifestApi();
    }
  }, [dropStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let AllManifest = equipments?.filter((data) => data.status === 'Manifest');

  const RunAddmanifestApi = async (id) => {
    const selectedValue = equipmentCategory;
    const selectedItem = equipmentCategoryDatas.find(
      (data) => data.EquipmentCategoryName === selectedValue
    );
    let neManifest = AllManifest.map((data) => ({
      EquipmentSknNumber: data.EquipmentSknNumber,
      EquipmentCategoryName: data.EquipmentCategoryName,
      EquipmentId: data.EquipmentId,
    }));
    await axios.post(`${ApiUrl2}api/Manifest/UpdateManifestEquipmentList?manifestId={${manifestId}}&equipmentcategoryId=${selectedItem?.EquipmentCategoryId}`, neManifest);
  };

  useEffect(() => {
    if (availableEquipment && savedEquipment) {
      setEquipments(availableEquipment.concat(savedEquipment));
    }
  }, [availableEquipment]); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(savedEquipment);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStylemoreWidth, height: '90%' }}>
          <IconButton aria-label="delete" className="coss_icon_export" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
          <Grid
            container
            spacing={2}
            className="d-flex flex-wrap flex-sm-nowrap"
            style={{ height: 'calc(100% - 50px)', display: 'flex', alignItems: 'stretch' }}
          >
            {/* Left Container */}
            <Grid
              item
              xs={12}
              sm={6}
              className="order small-box mt-3"
              style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            >
              <Heading1
                classNames="mb-3"
                title="Available Equipment List"
                style={{
                  position: 'sticky',
                  top: 0,
                  background: '#fff', // Add background to prevent content from overlapping
                  zIndex: 10,
                  padding: '10px 0',
                }}
              />
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                }}
                onDragLeave={(e) => onDragLeave(e)}
                onDragEnter={(e) => onDragEnter(e)}
                onDragEnd={(e) => onDragEnd(e)}
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e, false, 'equipment')}
              >
                <section className="drag_container" style={{ height: '100%' }}>
                  <div className="drag_column" style={{ height: '100%' }}>
                    <div className="drag_row">
                      {equipment?.map((task, i) => (
                        <div
                          className="card my-2"
                          key={i}
                          id={task.id}
                          draggable
                          onDragStart={(e) => onDragStart(e, task.EquipmentId)}
                          onDragEnd={(e) => onDragEnd(e)}
                        >
                          <Grid container flexDirection={"column"} padding={1}>
                            <Typography fontWeight={"700"} color={"#007C7C"}>
                              Equipment SKN Number :{task.EquipmentSknNumber}
                            </Typography>
                            <Typography>
                              Supplier Description :{task.SupplierDescription || 'NA'}
                            </Typography>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </Grid>

            {/* Right Container */}
            <Grid
              item
              xs={12}
              sm={6}
              className="done small-box mt-3"
              style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            >
              <Heading1
                classNames="mb-3"
                title="Add to RopeKit List"
                style={{
                  position: 'sticky',
                  top: 0,
                  background: '#fff',
                  zIndex: 10,
                  padding: '10px 0',
                }}
              />
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                }}
                onDragLeave={(e) => onDragLeave(e)}
                onDragEnter={(e) => onDragEnter(e)}
                onDragEnd={(e) => onDragEnd(e)}
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e, true, 'Manifest')}
              >
                <section className="drag_container" style={{ height: '100%' }}>
                  <div className="drag_column" style={{ height: '100%' }}>
                    <div className="drag_row">
                      {Manifest?.map((task, i) => (
                        <div
                          className="card my-2"
                          key={i}
                          id={task.id}
                          draggable
                          onDragStart={(e) => onDragStart(e, task.EquipmentId)}
                          onDragEnd={(e) => onDragEnd(e)}
                        >
                          <Grid container flexDirection={"column"} padding={1}>
                            <Typography fontWeight={"700"} color={"#007C7C"}>
                              Equipment SKN Number :{task.EquipmentSknNumber}
                            </Typography>
                            <Typography>
                              Supplier Description :{task.SupplierDescription || 'NA'}
                            </Typography>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>

  );
}
