import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Card, Grid } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
// sections
import { LoginForm } from '../../sections/auth/login';
import login from '../../images/login.png';
import { Link1 } from 'src/components/links/MyLink';
import logo from '../../images/logo.png';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  position: 'relative', // ensure the child element can be absolutely positioned
}));

const StyledLogoDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: "center",
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  position: 'relative',
}));

const VersionNumber = styled(Box)(({ theme }) => ({
  width: "100%",
  position: 'absolute',
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  textAlign: "right",
  fontSize: '16px',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      {/* {/* <Helmet>
        <title> Login | Minimal UI </title>
      </Helmet> */}

      {/* <StyledRoot> */}
      {/* // <Logo
        //   sx={{
        //     position: 'fixed',
        //     top: { xs: 16, sm: 24, md: 40 },
        //     left: { xs: 16, sm: 24, md: 40 },
        //   }}
        // />

        // {mdUp && (
        //   <StyledSection>
        //     <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
        //       Hi, Welcome
        //     </Typography>
        //     <img src={login} alt="login" />
        //   </StyledSection>
        // )} */}

      {/* <Container > */}
      <StyledContent>
        {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''} */}
        {/* <Link variant="subtitle2" to="/register">
                Get started
              </Link> */}
        {/* <Link1 to="/register" title="Get started" />
            </Typography> */}

        <Card style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} >
          <Grid container>
            <StyledLogoDiv >
            <Grid container style={{ width: "300px", height: "100%", justifyContent:"center"}}>
            <img src={logo} alt="login" />
            </Grid>
            </StyledLogoDiv>
            <LoginForm />
          </Grid>
        </Card>
        <VersionNumber>
          Version (1.0.23)
        </VersionNumber>
      </StyledContent>
      {/* </Container> */}
      {/* </StyledRoot> */}
    </>
  );
}
