import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPaginationEquipmentData, getAllConformity, getAllEquipmentCategoryData, getAllEquipmentData, getEquipmentData, getStatusData } from 'src/redux/equipmentSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchEquipData = () => {
  const dispatch = useDispatch();
  const equipmentDatas = useSelector((state) => state.equipment.equipmentData);
  const allEquipmentDatas = useSelector((state) => state.equipment.allEqupmentData);
  const equipmentData = useSelector((state) => state.equipment.equipmentData);
  const equipmentCategoryDatas = useSelector((state) => state.equipment.allEqupmentCategoryData);
  // const statusData = useSelector((state) => state.equipment.statusData);

  const [searchText, setSearchText] = useState('');
  const [equipmentIdData, setEquipmentIdData] = useState();
  const [equipmentListData, setEquipmentListData] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [latestPage, setLatestPage] = useState(0);
  const [loading, setloading] = useState(false);
  const [sortingArray, setSortingArray]= useState(null);
  const sortingData = sortingArray?.[0];


  useEffect(() => {
    // if (searchText !== '') {
    const getData = setTimeout(() => {
      setPaginationModel({ ...paginationModel, page: 0 });
      setLatestPage(0);
      fetchEquipData();
    }, 1000);
    return () => clearTimeout(getData);
    // }
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (paginationModel.page > latestPage) {
      setLatestPage(latestPage + 1);
    }
  }, [paginationModel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestPage > 0) {
      fetchEquipData();
    }
  }, [latestPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (statusData < 0) {
  //     getStatusListData();
  //   }
  // }, [statusData]);

  const getStatusListData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=Status`);
      if (response.status === 200) {
        dispatch({
          type: getStatusData,
          payload: JSON.parse(response.data),
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchEquipData = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        `${ApiUrl2}api/Equipment/GetEquipmentData?pageNumber=${ 1}&pageSize=${(paginationModel?.pageSize )*(latestPage + 1)}&searchText=${searchText}&sortColumn=${sortingData?.field}&sortOrder=${sortingData?.sort}`
      );
  
      if (response.status === 200) {
        const receivedData = JSON.parse(response.data).Data.map((item) => ({
          ...item,
          id: item.EquipmentId,
        }));
        
        const updatedData =[...receivedData];
  
        dispatch({
          type: getEquipmentData,
          
          payload: updatedData,
        });
  
        setTotalRowCount(JSON.parse(response?.data)?.TotalCount);
        setEquipmentListData(updatedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  }

  const getEquipmentDataUsingId = async (id) => {
    setEquipmentIdData();
    try {
      const response = await axios.get(`${ApiUrl2}api/Equipment/GetSingleEquipmentData?EquipmentId=${id}`);
      if (response.status === 200) {
        setEquipmentIdData(JSON.parse(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchEquipCatData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/EquipmentCategory/GetEquipmentCategoryData`);
      if (response.status === 200) {
        const result = JSON.parse(response.data).map((item, i) => {
          return { ...item, id: i };
        });
        dispatch({
          type: getAllEquipmentCategoryData,
          payload: result,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchConformityListData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/EquipmentCategory/GetConformityList`);
      if (response.status === 200) {
        const result = JSON.parse(response.data);
        dispatch({
          type: getAllConformity,
          payload: result,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //   if (equipmentDatas === null) {
  //     fetchEquipData();
  //   }
  // }, [equipmentDatas]);

  return {
    equipmentDatas,
    fetchEquipData,
    paginationModel,
    setPaginationModel,
    loading,
    searchText,
    setSearchText,
    allEquipmentDatas,
    //  fetchAllEquipData,
    getEquipmentDataUsingId,
    equipmentIdData,
    equipmentListData,
    fetchEquipCatData,
    equipmentCategoryDatas,
    // statusData,
    getStatusListData,
    totalRowCount,
    fetchConformityListData,
    sortingArray, 
    setSortingArray
  };
};
