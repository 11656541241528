import { Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import EditEquipmentModal from './EditEquipmentModal';
import DataTable from 'src/components/datatable/DataTable';
import UploadExcelModal from './UploadExcelModal';
import { useEffect } from 'react';
import DeleteBox from './DeleteBox';
import { useFetchCategoryData } from '../equipment_category/usefetchEquipmentData';
import { useFetchEquipData } from './useFetchEquipment';
import Loader from 'src/components/loader/Loader';
import { InputField1 } from 'src/components/inputFields/InputField';
import { useFetchfilterEquipment } from './useFetchFilterEquipment';
import dayjs from 'dayjs';
import { CustomToolBarButton } from '../manifest/CustomToolBarButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useFetchOrderData } from '../purchaseOrder/useFetchOrderData';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';

const CatArr = ['Cat1', 'Cat2', 'Cat3'];

export const equipmetTypeData = [
  {
    name: 'Ducks',
    value: '0739a16-eca7-4337-b582-5d60a75ae624',
  },
  {
    name: 'Maillons',
    value: 'c12168de-0755-4754-b5a7-ce49476d00cb',
  },
];

const Equipments = () => {
  const [open, setOpen] = useState(false);
  const [openuploadModal, setOpenUploadModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { fetchOrderData, purchaseOrderData } = useFetchOrderData();
  const statusData = useSelector((state) => state.equipment.statusData);
  const equipmentData = useSelector((state) => state.equipment.equipmentData);
  // const allConformities =useSelector((state)=>state.equipment.allConformityList)
  const {
    filterArray,
    setFilterArray,
    fetchFilterEquipData,
    filterEquipData,
    filterLoading,
    filterPaginationModel,
    setFilterPaginationModel,
    filterSortingArray, 
    setFilterSortingArray,
    filteredTotalRowCount
  } = useFetchfilterEquipment();

  const {
    equipmentListData,
    fetchEquipData,
    paginationModel,
    setPaginationModel,
    getEquipmentDataUsingId,
    equipmentIdData,
    getStatusListData,
    totalRowCount,
    loading,
    sortingArray,
    setSortingArray,
    fetchConformityListData
  } = useFetchEquipData();


  useEffect(() => {
    if (statusData !== null) {
      getStatusListData();
    }
  }, []);
  
  // useEffect(() => {
  //   fetchConformityListData();
  // }, []);

  useEffect(() => {
    if (purchaseOrderData === null) {
      fetchOrderData();
    }
  }, [purchaseOrderData]);

  useEffect(() => {
    // if (equipmentData === null) {
      fetchEquipData();
    // }
  }, [sortingArray]);

  const actions = ['Delete'];
  const handleModalAction = (actionType) => {
    switch (actionType) {
      case 'Delete':
        handleDeleteRow(editData);
        break;
      default:
        break;
    }
  };
  const handleModalOpen = (data) => {
    setEditData(data);
  };

  const fetchEquipmentExcel = async (row) => {
    try {
      const response = await axios.get(
        `${ApiUrl2}api/Equipment/DownloadEquipmentXlsx`,
        {
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `Equipment.xlsx`; 
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading NCR Excel file:', error);
    }
  };

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowIndex = params.row.id;
        return (
          <input
            type="checkbox"
            id={rowIndex}
            disabled={params.row.Status==="Deleted" || params.row.ManifestName || false }
            checked={editData?.EquipmentId === params?.row?.EquipmentId}
            onChange={() => handleModalOpen(params.row.EquipmentId === editData?.EquipmentId ? null : params.row)}
          />
        );
      },
    },
    {
      field: 'SKNNumber',
      headerName: 'SKN Number',
      sortable: true,
      width: 130,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleEditRow(params.row)}>
          {params.row.SKNNumber}
        </div>
      ),
      valueGetter: (params) => params.row.SKNNumber,
    },
    {
      field: 'PONumber',
      headerName: 'PO Number',
      sortable: true,
      width: 160,
      valueGetter: (params) => params.row.PONumber,
    },
    {
      field: 'EquipmentCategoryName',
      headerName: 'Equipment Category Name',
      sortable: true,
      width: 260,
      valueGetter: (params) => params.row.EquipmentCategoryName,
    },
    // {
    //   field: 'EquipmentDescription',
    //   headerName: 'Equipment Description',
    //   sortable: true,
    //   width: 160,
    //   valueGetter: (params) => params.row.EquipmentDescription,
    // },
    {
      field: 'SupplierDescription',
      headerName: 'Supplier Description',
      sortable: true,
      width: 260,
      valueGetter: (params) => params.row?.SupplierDescription,
    },
    {
      field: 'ManifestName',
      headerName: 'Ropekit Name',
      sortable: true,
      width: 220,
      valueGetter: (params) => params.row.ManifestName,
    },

    {
      field: 'Client',
      headerName: ' Client',
      sortable: true,
      width: 160,
      valueGetter: (params) => params.row.Client,
    },

    {
      field: 'Location',
      headerName: ' Location',
      sortable: true,
      width: 140,
      valueGetter: (params) => params.row.Location,
    },

    {
      field: 'LastInspectionDate',
      headerName: ' Last Inspection Date',
      sortable: true,
      width: 160,
      valueGetter: (params) => params.row.LastInspectionDate ?  dayjs(params.row.LastInspectionDate).format('DD-MM-YYYY') :"",
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1?.split('-')?.map(part => parseInt(part, 10));
        const date2Parts = v2?.split('-')?.map(part => parseInt(part, 10));
    
        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }
      
        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }
        
        return date1Parts[0] - date2Parts[0];
      },
    },

    {
      field: 'NextInspectionDate',
      headerName: 'Next Inspection Date',
      sortable: true,
      width: 180,
      valueGetter: (params) =>  params?.row?.NextInspectionDate? dayjs(params?.row?.NextInspectionDate).format('DD-MM-YYYY') :"",
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1?.split('-')?.map(part => parseInt(part, 10));
        const date2Parts = v2?.split('-')?.map(part => parseInt(part, 10));
    
        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }
      
        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }
        
        return date1Parts[0] - date2Parts[0];
      },
    },
    {
      field: 'NCRNumber',
      headerName: 'NCR Number',
      sortable: true,
      width: 160,
      valueGetter: (params) => params.row.NCRNumber,
    },
    {
      field: 'Status',
      headerName: 'Status',
      sortable: true,
      width: 140,
      valueGetter: (params) => {
        const status = params?.row?.Status;
    
        if (status === "IS" || status === "SP") {
          return status;
        }

        return status
          ?.split(" ")                   
          ?.map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )                             
          ?.join(" ");
      },
    }
  ];
  const formatRupee = (number) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(number);
  };

  const handleEditRow = (row) => {
    if (row) {
      setOpen(true);
      getEquipmentDataUsingId(row.EquipmentId);
      setEditData(row);
    } else {
      toast.error(`Please select row`);
    }
  };

  const handleDeleteRow = (row) => {
    if (row) {
      setDeleteOpen(true);
      setDeleteId(row.EquipmentId);
    } else {
      toast.error(`Please select row`);
    }
  };

  useEffect(() => {
    const FetchfilterEquipment = () => {
      if (filterArray?.items?.[0]?.value?.length > 2) {
        fetchFilterEquipData();
      }
    };
    FetchfilterEquipment();
  }, [filterArray,filterSortingArray ]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <Typography variant="h4" className="mx-2 mx-xl-0 mb-3">
          Equipment
        </Typography>

        <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
          <PrimaryButton
            title="Add New Equipment"
            size="large"
            classNames="mx-2 mx-xl-0 mb-3"
            onClick={() => setOpen(true)}
          />
          <SecondaryButton
            title="Upload excel"
            size="large"
            classNames="mx-2 mx-xl-0 mb-3"
            onClick={() => setOpenUploadModal(true)}
          />
        </div>
      </div>
      {/* <div className="container-fluid p-xl-0">
        <div className="row p-0">
          <div className="col-sm-6">
            <InputField1
              placeholder="Search Equipment Here"
              classNames="mb-4 w-100 "
              autoComplete="off"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div> */}

      {/* Show loader only if both equipmentListData and filterEquipData are falsy */}
      {!(equipmentData || filterEquipData) || loading ? <Loader /> : null}
      
      {equipmentData !== null && (
        <DataTable
          tableName='Equipments'
          columns={columns}
          rows={filterEquipData !== null ? filterEquipData : equipmentData}
          paginationModel={filterEquipData !== null ? filterPaginationModel : paginationModel}
          setPaginationModel={filterEquipData !== null ? setFilterPaginationModel : setPaginationModel}
          setFilterArray={setFilterArray}
          filterArray={filterArray}
          isFilterData={filterEquipData !== null ? true : false}
          setFilterSortingArray={setFilterSortingArray}
          setSortingArray={setSortingArray}
          filterSortingArray={ filterSortingArray}
          sortingArray={sortingArray}
          renderEmptyCell={() => <h1>No data Found</h1>}
          toolbarNotRequired
          filterMode="server"
          sortMode="server"
          backendFilter="Equipment"
          totalCountEqui={filterEquipData !== null ? filterSortingArray :totalRowCount}
          fetchEquipmentExcel={fetchEquipmentExcel}
          CutomToolBarButton={<CustomToolBarButton actions={actions} handleModalAction={handleModalAction} />}
        />
      )}

      <DeleteBox open={deleteOpen} setOpen={setDeleteOpen} deleteId={deleteId} setDeleteId={setDeleteId} />

      <EditEquipmentModal
        open={open}
        setOpen={setOpen}
        CatArr={CatArr}
        // purchaseOrderData={purchaseOrderData?.filter(
        //   (item) => (item.Status != 'Completed' && item.Status != 'Deleted')
        // )}
        purchaseOrderData={purchaseOrderData}
        // allConformities={ allConformities}
        editData={editData}
        setEditData={setEditData}
        fetchEquipData={fetchEquipData}
        equipmentListData={equipmentData}
        equipmentIdData={editData ? equipmentIdData : null}
        statusData={statusData}
      />
      <UploadExcelModal open={openuploadModal} setOpen={setOpenUploadModal} fetchEquipData={fetchEquipData} />
    </>
  );
};

export default Equipments;
