// EditPurchaseModal.jsx
import {
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { DeleteButton, PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { CustomSelect, InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Validator from 'validatorjs';
import { MenuItem } from '@mui/material';
import { CustomDate } from 'src/components/date_picker/CustomDatePicker';
import dayjs from 'dayjs';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import SupplierSelect from './SupplierSelect';
import { useFetchOrderData } from './useFetchOrderData';

export default function EditPurchaseModal({ open, setOpen, editData, setEditData, supplierListData }) {
  const [addData, setAddData] = useState([]);
  const [previusCount, setPreviusCount] = useState(0);
  const [equipmentCategory2, setEquipmentCategory2] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [comments, setComments] = useState('');
  const [date, setDate] = useState(dayjs(''));
  const [equipmentCategoryNames, setEquipmentCategoryNames] = useState([]);
  const [equipmentCategoryDescriptions, setEquipmentCategoryDescriptions] = useState([]);
  const { fetchOrderData } = useFetchOrderData();
  const fetchEquipmentCategoryData = async (selectedSupplierName) => {
    try {
      const response = await axios.get(
        `${ApiUrl2}api/PurchaseOrder/GetEquipmentCategoryData?supplierName=${selectedSupplierName}`
      );
      if (response.status === 200) {
        const dataString = response.data;
        const data = JSON.parse(dataString);
        if (Array.isArray(data)) {
          setEquipmentCategoryNames(data);
        } else {
          console.error('Invalid data format received from the API:', data);
        }
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching equipment category data:', error);
    }
  };

  const fetchEquipmentCategoryDesData = async (equipmentCategoryId) => {
    try {
      const response = await axios.get(
        `${ApiUrl2}/api/PurchaseOrder/GetSupplierDescription?equipmentCategoryId=${equipmentCategoryId}`
      );
      if (response.status === 200) {
        const dataString = response.data;
        const data = JSON.parse(dataString);
        if (Array.isArray(data)) {
          // console.log("descriptionData", data);
          setEquipmentCategoryDescriptions((prevDescriptions) => [
            ...prevDescriptions,
            { categoryId: equipmentCategoryId, descriptions: data }
          ]);
          return(data)
        } else {
          console.error('Invalid data format received from the API:', data);
        }
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching equipment category data:', error);
    }
  };

  const deleteDescriptionByCategoryId = (categoryId) => {
    setEquipmentCategoryDescriptions((prevDescriptions) =>
      prevDescriptions.filter(item => item.categoryId !== categoryId)
    );
  };
  const handleSupplierNameChange = (e) => {
    const selectedSupplierName = e.target.value;
    setSupplierName(selectedSupplierName);
    fetchEquipmentCategoryData(selectedSupplierName);
    setAddData([]);
    setEquipmentCategory2('');
    setEquipmentCategoryDescriptions([])
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
    setPoNumber('');
    setDate(dayjs(''));
    setAddData([]);
    setSupplierName('');
    setEquipmentCategory2('');
    setComments('');
    setPreviusCount(0)
    setEquipmentCategoryDescriptions([])
  };

  useEffect(() => {
    if (editData !== null) {
      setPoNumber(editData.PONumber);
      const dateParts = editData.PODate.split('/');
      if (dateParts.length === 3) {
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];
        const isoDate = `${year}-${month}-${day}`;
        setDate(dayjs(isoDate));
      }
      setAddData(editData.EquipmentCategoryAndQuantityList || []);
      setPreviusCount(editData.EquipmentCategoryAndQuantityList.length)
      setSupplierName(editData.SupplierName);
      setComments(editData.Comments);
      fetchEquipmentCategoryData(editData.SupplierName);

      const processEquipmentCategoriesSequentially = async () => {
        for (const item of editData.EquipmentCategoryAndQuantityList) {
          await fetchEquipmentCategoryDesData(item.EquipmentCategoryId);
        }
      };

      processEquipmentCategoriesSequentially();
     
    }else{
      setPreviusCount(0)
      setEquipmentCategoryDescriptions([])
    }
  }, [editData]);

  const handleFormSubmit = async () => {

    const isSupplierDescriptionEmpty = addData.some(item => !item.SupplierDescription || item.SupplierDescription.trim() === '');

  if (isSupplierDescriptionEmpty) {
    toast.error('Supplier Description is required for all categories.');
    return; 
  }

    const formData = {
      PONumber: poNumber,
      PODate: date.format('YYYY-MM-DD'),
      EquipmentCategoryAndQuantityList: addData,
      SupplierName: supplierName,
      Comments: comments,
      // SupplierCode: addData.SupplierCode,
      // SupplierDescription: addData.SupplierDescription,
    };

    const checkdata = {
      PONumber: 'required',
      PODate: 'required|date',
      EquipmentCategoryAndQuantityList: 'required|array',
      SupplierName: 'required',
    };

    const validation = new Validator(formData, checkdata);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((errorArray) => {
        errorArray.map((error) => {
          if (error.includes('PONumber')) {
            // Customize the error message for the "PO Date is required" error
            toast.error(`PO Number is required. Please enter a value.`);
          } else if (error.includes('PODate')) {
            // Customize the error message for the "PO Date is required" error
            toast.error(`PO Date is required. Please select a date.`);
          } else if (error.includes('EquipmentCategoryAndQuantityList')) {
            // Customize the error message for the "PO Date is required" error
            toast.error(`Equipment Category is required. Please select a Equipment Category.`);
          } else if (error.includes('SupplierName')) {
            // Customize the error message for the "PO Date is required" error
            toast.error(`Supplier Name is required. Please select a Supplier Name.`);
          } else if (error.includes('SupplierDescription')) {
            // Customize the error message for the "PO Date is required" error
            toast.error(`Supplier Description is required. Please select a Supplier Description.`);
          } else {
            // Display other error messages as they are
            toast.error(`${error}`);
          }
        });
      });
    } else {
      try {
        if (typeof editData?.POId !== 'undefined') {
          formData.POId = editData.POId;
        }
        const response = await axios.post(`${ApiUrl2}api/PurchaseOrder/InsertSinglePurchaseOrderData`, formData);
        if (response.status === 200) {
          toast.success(`PO ${editData !== null ? 'Edit' : 'Created'} Successfully`);
          fetchOrderData();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.Message || 'Error adding Order');
      }
    }
  };

  const getCategoryAdd = async (data) => {
    const findCategory = equipmentCategoryNames.find((item) => item.EquipmentCategoryName === data);
    
    if (!findCategory) {
      return;
    }
  
    const fetchedData = await fetchEquipmentCategoryDesData(findCategory?.EquipmentCategoryId);
    
    if (fetchedData) {
      setAddData([
        ...addData,
        {
          EquipmentCategoryId: findCategory?.EquipmentCategoryId,
          EquipmentCategoryName: findCategory?.EquipmentCategoryName,
          SupplierCode: fetchedData[0]?.SupplierCode || '', 
          SupplierDescription: fetchedData[0]?.SupplierDescription || '', 
          Quantity: 1,
        },
      ]);
    } else {
      console.error("Error: No data returned from the fetch.");
    }
  };
  
  const handleQuantityChange = (index, newValue, item) => {
    const newData = [...addData];
    const updatedItem = { ...newData[index] };
    updatedItem.Quantity = newValue;
    newData[index] = updatedItem;
    setAddData(newData);
  };

  const handleSupplierDesChange = (index, newValue, item) => {
    const newData = [...addData];
    const updatedItem = { ...newData[index] };
    const AddedData= equipmentCategoryDescriptions[index]?.descriptions.find((category, index)=> category.SupplierDescription==newValue)
    // console.log("AddedData",equipmentCategoryDescriptions[index])
    updatedItem.SupplierCode= AddedData.SupplierCode;
    updatedItem.SupplierDescription = newValue;
    newData[index] = updatedItem;
    setAddData(newData);
  };

  const handleNotDelete = () => {
    toast.error("Can't delete the previously added categories.");
  }
  const handleDelete = (data, i) => {
    setEquipmentCategory2('');
    deleteDescriptionByCategoryId(data?.EquipmentCategoryId)
    const newData = [...addData];
    setAddData(newData.filter((item, index) => index !== i));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4">{editData !== null ? 'Edit' : 'Add New'} Order</Typography>
          <InputField1
            id="PoNumber"
            name="PoNumber"
            fullWidth
            disabled={editData?.Status == "Deleted" || editData?.Status == "Completed" ||editData ? true : false}
            autoComplete="on"
            label="PO Number"
            value={poNumber}
            onChange={(e) => setPoNumber(e.target.value)}
            margin="normal"
          />
          <CustomDate
            id="PoDate"
            name="PoDate"
            disabled={editData?.Status == "Deleted" || editData?.Status == "Completed" ||editData  ? true : false}
            label="Purchase Order date"
            margin="normal"
            value={date}
            autoComplete="on"
            onChange={(e) => setDate(e)}
          />
          <CustomSelect
            id="SupplierName"
            name="SupplierName"
            margin="normal"
            label="Supplier Name"
            value={supplierName}
            autoComplete="on"
            onChange={handleSupplierNameChange}
            menus={
              supplierListData?.length > 0 &&
              supplierListData.map((data, i) => (
                <MenuItem key={i} value={data.SupplierName}>
                  {data.SupplierName}
                </MenuItem>
              ))
            }
            disabled={editData ? true : false}
          />
          <CustomSelect
            id="EquipmentCategory"
            name="EquipmentCategory"
            margin="normal"
            label={`Equipment Category`}
            value={equipmentCategory2}
            autoComplete="on"
            icon={true}
            toolTipContent="You can add multiple categories."
            disabled={editData?.Status == "Deleted" || editData?.Status == "Completed" ||editData ? true : false}
            onChange={(e) => getCategoryAdd(e.target.value)}
            menus={
              equipmentCategoryNames?.length > 0 ? (
                equipmentCategoryNames.map((category) => (
                  <MenuItem key={category.EquipmentCategoryName} value={category.EquipmentCategoryName}>
                    {category.EquipmentCategoryName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No data available</MenuItem>
              )
            }
          />

          {/* Table to display information */}
          <TableContainer>
            <Table sx={{ border: '1px solid #ddd' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Equipment Category</TableCell>
                  <TableCell>Supplier Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addData.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell style={{ minWidth: '100px', maxWidth: '150px', overflow: 'hidden' }}>
                      <span id="EqNm">{item.EquipmentCategoryName}</span>
                    </TableCell>
                    <TableCell style={{ minWidth: '300px', maxWidth: '350px', overflow: 'hidden' }}>
                      <Select
                      sx={{ width: '400px' }}
                        id={'supplierDescription'}
                        name={'supplierDescription'}
                        autoComplete="on"
                        value={item.SupplierDescription}
                        onChange={(e) => handleSupplierDesChange(i, e.target.value, item)}
                        disabled={i < previusCount ? true : false}
                      >
                        {equipmentCategoryDescriptions[i]?.descriptions?.map((category, index) => (
                          <MenuItem key={index} value={category.SupplierDescription}>
                            {category.SupplierDescription}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <SupplierSelect
                        item={item}
                        addData={addData}
                        setAddData={setAddData}
                        i={i}
                        disabled={i<previusCount ? true : false}
                        equipmentCategoryNames={equipmentCategoryNames}
                        supplierListData={equipmentCategoryDescriptions}
                      /> */}
                    </TableCell>
                    <TableCell style={{ minWidth: '100px', maxWidth: '100px', overflow: 'hidden' }}>
                      <InputField1
                        id={'quantity'}
                        name={'quantity'}
                        disabled={i < previusCount ? true : false}
                        autoComplete="on"
                        value={item.Quantity}
                        onChange={(e) => handleQuantityChange(i, e.target.value, item)}
                        type="number"
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: '20px', maxWidth: '20px', overflow: 'hidden' }}>
                      <DeleteButton color={i < previusCount ? "gray" : "error"} onClick={() => { i < previusCount ? handleNotDelete() : handleDelete(item, i) }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <InputField1
            id="Comments"
            name="Comments"
            fullWidth
            label="Comments"
            value={comments}
            disabled={editData?.Status == "Deleted" || editData?.Status == "Completed" ||editData ? true : false}
            autoComplete="on"
            onChange={(e) => setComments(e.target.value)}
            margin="normal"
          />
          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
