import { DataGrid, GridToolbar, GridToolbarExportContainer } from '@mui/x-data-grid';
import './style.css';
import { PrimaryButton } from '../buttons/MyButton';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import { GridToolbarFilterButton } from '@mui/x-data-grid';
import { GridToolbarDensitySelector } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

const DataTable = ({
  tableName,
  columns,
  rows,
  initialState,
  renderEmptyCell,
  paginationModel,
  setPaginationModel,
  backendFilter,
  setFilterArray,
  filterArray,
  setFilterSortingArray,
  setSortingArray,
  filterSortingArray,
  sortingArray,
  filterMode = "client",
  sortMode = "client",
  CutomToolBarButton,
  isGenericPage = false,
  totalCountEqui,
  height = 520,
  isFilterData,
  fetchEquipmentExcel
}) => {
  const columnsWithFilter = columns.map((column) => ({
    ...column,
    // filterable: true,
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '5px',
        }}
      >
        No data available
      </div>
    );
  };

  const handleExcelExport = () => {
    // Exclude the "id" field and format date fields during export
    const exportData = rows.map(({ id, ...rest }) => {
      const formattedRow = {};
      Object.keys(rest).forEach((key) => {
        const value = rest[key];
        // Check if the value is a valid date and format it
        if (dayjs(value, 'DD/MM/YYYY', true).isValid()) {
          formattedRow[key] = dayjs(value, 'DD/MM/YYYY').format('DD-MM-YYYY');
        } else {
          formattedRow[key] = value;
        }
      });
      return formattedRow;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, tableName || 'Data');
    XLSX.writeFile(workbook, `${tableName || 'export'}.xlsx`);
  };
  
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Box>
          <GridToolbarColumnsButton id="columns-button" name="columns-button" />
          {!isGenericPage && (
            <GridToolbarFilterButton id="filter-button" name="filter-button" />
          )}
          <GridToolbarDensitySelector id="density-button" name="density-button" />
          <Button
            color="primary"
            size="small"
            startIcon={
              <FileDownloadOutlinedIcon style={{ fontSize: '20px', fontWeight: 800 }} />
            }
            onClick={backendFilter === 'Equipment' ? fetchEquipmentExcel : handleExcelExport}
          >
            Export
          </Button>
        </Box>
        {CutomToolBarButton}
      </GridToolbarContainer>
    );
  }

  const handleFilterModelChange = (e) => {
    if (backendFilter === 'Equipment') {
      setFilterSortingArray(null)
      if (sortingArray?.length > 0 && filterSortingArray == null) {
        setFilterSortingArray(sortingArray)
      }
      setFilterArray(e);
    }
  };


  const handleSortModelChange = (e) => {
    if (backendFilter === 'Equipment') {
      if (isFilterData == true) {
        setFilterSortingArray(e)
      } else {
        setSortingArray(e)
      }
    }
  };

  return (
    <div style={{ height: height }}>
      <DataGrid
        renderEmptyCell={renderEmptyCell && renderEmptyCell}
        columns={columnsWithFilter}
        rows={rows}
        initialState={initialState}
        components={{
          // Toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        rowCount={totalCountEqui}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        filterMode={filterMode}
        sortMode={sortMode}
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
        }}
        disableColumnSort={backendFilter === 'Equipment'}
        filterModel={backendFilter === 'Equipment' ? undefined : filterArray}
        sortModel={backendFilter === 'Equipment' ? undefined : sortingArray}
      />
    </div>
  );
};

export default DataTable;
