import React, { useState, useEffect } from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import DataTable from 'src/components/datatable/DataTable';
import EquipmentEditModal from './EquipmentEditModal';
import DeleteBox from './DeleteBox';
import AddSupplierModal from './AddSupplierModal'; // Import the AddSupplierModal component
import { Typography } from '@mui/material';
import { useFetchCategoryData } from './usefetchEquipmentData';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import { CustomToolBarButton } from '../manifest/CustomToolBarButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const EquipmentCategory = () => {
  
  const [open, setOpen] = useState(false);
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const equipmentCategoryData =useSelector((state) => state.equipmentCategory.equipmentCategoryData);
  const allConformities =useSelector((state)=>state.equipmentCategory.allConformityList)
  const {
    equipmentCategoryDatas,
    fetchEquipCatData,
    GetSupplierData,
    supplierListData,
    GetEquipmentCategorySingleData,
    equipmentSingleData,
    fetchConformityListData
  } = useFetchCategoryData();

  const { paginationModel, setPaginationModel } = useDefaultPagination();

  const actions = [];
  const handleModalAction = (actionType) => {
    switch (actionType) {
      case 'Delete':
        handleDeleteRow(editData);
        break;
      default:
        break;
    }
  };
  const handleModalOpen = (data) => {
    setEditData(data);
  };

  useEffect(() => {
    fetchConformityListData();
  }, []);

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowIndex = params.id;
        return (
          <input
            type="checkbox"
            id={rowIndex}
            checked={editData?.EquipmentCategoryId === params?.row?.EquipmentCategoryId}
            onChange={() => handleModalOpen(editData?.EquipmentCategoryId === params?.row?.EquipmentCategoryId ? null :params.row)}
          />
        );
      },
    },
    {
      field: 'equipmentCategoryName',
      headerName: 'Equipment Category',
      width: 220,
      sortable: true,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleEditRow(params.row)}>
          {params.row.EquipmentCategoryName}
        </div>
      ),
      valueGetter: (params) => params.row.EquipmentCategoryName,
    },
    {
      field: 'supplierDescription',
      headerName: 'Supplier Description',
      width: 320,
      sortable: true,
      valueGetter: (params) => params.row.SupplierDescription || 'N/A', // Display 'N/A' if null
    },
    {
      field: 'supplierCode',
      headerName: 'Supplier Code',
      width: 220,
      sortable: true,
      valueGetter: (params) => params.row.SupplierCode || 'N/A', // Display 'N/A' if null
    },
    {
      field: 'supplierName',
      headerName: 'Supplier Name',
      width: 260,
      sortable: true,
      valueGetter: (params) => params.row.SupplierName || 'N/A', // Display 'N/A' if null
    },
    
  ];

  const handleEditRow = (row) => {
    GetEquipmentCategorySingleData(row?.EquipmentCategoryId, row?.SupplierCodeId);
    setEditData(row);
    setOpen(true);
  };

  const handleDeleteRow = (row) => {
    if (row) {
      setDeleteOpen(true);
      setDeleteId(row.EquipmentCategoryId);
    } else {
      toast.error(`Please select row`);
    }
  };
  useEffect(() => {
    if (supplierListData === null) {
      GetSupplierData();
    }
  }, [supplierListData]);

  useEffect(() => {
    if (equipmentCategoryData=== null) {
      fetchEquipCatData();
    }
  }, [equipmentCategoryData]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
        <Typography variant="h4" className="mx-2 mx-xl-0 mb-3">
          Equipment Categories
        </Typography>
        <PrimaryButton
          title="Add New Category"
          size="large"
          classNames="mx-2 mx-xl-0 mb-3"
          onClick={() => setOpen(true)}
        />
      </div>
      {equipmentCategoryData !== null && (
        <DataTable
        tableName='Equipment_Category'
          columns={columns}
          rows={equipmentCategoryData}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          renderEmptyCell={() => <h1>No data Found</h1>}
          toolbarNotRequired
          filterMode="client"
          CutomToolBarButton={<CustomToolBarButton actions={actions} handleModalAction={handleModalAction} />}
        />
      )}
      <EquipmentEditModal
        open={open}
        setOpen={setOpen}
        editData={editData}
        setEditData={setEditData}
        supplierListData={supplierListData}
        equipmentSingleData={equipmentSingleData}
        fetchEquipCatData={fetchEquipCatData}
        allConformities={ allConformities}
      />
      <DeleteBox open={deleteOpen} setOpen={setDeleteOpen} deleteId={deleteId} setDeleteId={setDeleteId} />
      <AddSupplierModal
        open={addSupplierModalOpen}
        setOpen={setAddSupplierModalOpen}
        equipmentCategoryId={editData?.EquipmentCategoryId}
      />
    </>
  );
};

export default EquipmentCategory;
