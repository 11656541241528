import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const CustomDatePicker = styled(DatePicker)({
  '& label.Mui-focused': {
    color: `var(--500)`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `var(--500)`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `var(--500)`,
    },
    '&:hover fieldset': {
      borderColor: `var(--500)`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `var(--500)`,
    },
  },
});

export const CustomDate = ({
  id,
  name,
  label,
  classNames,
  style,
  onChange,
  value,
  readOnly,
  autoComplete,
  disabled = false,
  minDate,
  maxDate
}) => {
  return (
    <div style={style} className={`date_pick_cont ${classNames}`} id={id} name={name}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDatePicker
          id={id}
          name={name}
          label={label}
          fullWidth
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly && true}
          renderInput={(props) => <TextField {...props}  id={id} name={name} />}
        format="DD-MM-YYYY"
        autoComplete={autoComplete && autoComplete}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </div>
  );
};
