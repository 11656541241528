import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { CustomSelect, InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { useEffect } from 'react';
import { CustomDate } from 'src/components/date_picker/CustomDatePicker';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Select, Chip, TextField, TableHead, TableRow, TableCell, TableBody, TableContainer, Table, FormControl, InputLabel, } from '@mui/material';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addEquipmentData, addUpdatedEquipment } from 'src/redux/equipmentSlice';
import Validator from 'validatorjs';
import { useFetchfilterEquipment } from './useFetchFilterEquipment';
import { useFetchEquipData } from './useFetchEquipment';
import { useState } from 'react';
import { Padding } from '@mui/icons-material';
import { useFetchOrderData } from '../purchaseOrder/useFetchOrderData';
export default function EditEquipmentModal({
  open,
  setOpen,
  editData,
  setEditData,
  equipmentIdData,
  statusData,
  purchaseOrderData,
  // allConformities
}) {

  const { fetchOrderData } = useFetchOrderData();
  const [manufacturerNumber, setManufacturerNumber] = React.useState('');
  const [certificateNumber, setCertificateNumber] = React.useState('');
  const [poNumber, setPoNumber] = React.useState('');
  const [purchaseDate, setPurchaseDate] = React.useState('');
  const [purchaseValue, setPurchaseValue] = React.useState('');
  const [conformity, setConformity] = React.useState('');
  const [status, setStatus] = React.useState('');
  // const [newConformityOption, setNewConformityOption] = useState('');
  // const [conformityList, setConformityList] = useState( allConformities || []);
  // const [equipmentdescription, setequipmentdescription] = React.useState('');
  const [equipmentCategory2, setEquipmentCategory2] = React.useState('');
  const [newEquipmentCategory2, setNewEquipmentCategory] = React.useState('');
  const [sknNumber, setSKNNumber] = React.useState('');
  const [firstInspectionDate, setFirstIspectionDate] = React.useState('');
  const [lastInspectionDate, setLastInspectionDate] = React.useState('');
  const [nextInspectionDate, setNextInspectionDate] = React.useState('');
  const [ncrNumber, setNcrNumber] = React.useState('');
  const [supplierName, setSupplierName] = React.useState('');
  const [equipmentCategoryData, setEquipmentCategoryData] = useState()
  const [supplierDescription, setSupplierDescription] = React.useState('test description');
  const [supplierCode, setSupplierCode] = React.useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
    setManufacturerNumber('')
    const currentDate = new Date();
    const purchase = dayjs(currentDate)
    setPurchaseDate(purchase);
    setCertificateNumber('')
    setPoNumber('')
    setConformity('')
    setSupplierName('')
    setSupplierCode('')
    setSupplierDescription('')
    // setNewConformityOption('')
    // setConformityList([])
    setStatus('SP')
    // setequipmentdescription('')
    setEquipmentCategory2('')
    setSKNNumber('')
    setFirstIspectionDate('')
    setPurchaseValue('');
    setLastInspectionDate('')
    setNextInspectionDate('')
    setNewEquipmentCategory('');

  };

  const handleFormSubmit = async () => {
    const nextInspection = dayjs(purchaseDate).add(6, 'month');

    const formData = {
      EquipmentCategoryId: equipmentCategory2?.EquipmentCategoryId,
      SKNNumber: sknNumber,
     ManufacturerNumber: manufacturerNumber,
      CertificateNumber: certificateNumber,
      PONumber: poNumber?.PONumber,
      PurchaseDate: dayjs(purchaseDate).format('YYYY-MM-DD'),
      PurchaseValue: purchaseValue * 1,
      Conformity: conformity,
      FirstInspectionDate: dayjs(purchaseDate).format('YYYY-MM-DD'),
      LastInspectionDate: dayjs(purchaseDate).format('YYYY-MM-DD'),
      NextInspectionDate: dayjs(nextInspection).format('YYYY-MM-DD'),
      Status: status,
      NCRNumber: ncrNumber,
      SupplierDescription: supplierDescription
      // EquipmentDescription: 'test description',
    };
  
    const validationRules = {
      EquipmentCategoryId: 'required',
      SKNNumber: 'required',
      ManufacturerNumber: 'required',
      CertificateNumber: 'required',
      PONumber: 'required',
      PurchaseDate: 'required',
      PurchaseValue: 'required|numeric|min:0',
      Conformity: 'required',
      FirstInspectionDate: 'required',
      // LastInspectionDate: 'required',
      // NextInspectionDate: 'required',
      Status: 'required',
      // NCRNumber: 'required',
      // EquipmentDescription: 'required',
    };

    const validation = new Validator(formData, validationRules);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((errorArray) => {
        errorArray.map((error) => {
          if (error.includes('EquipmentCategoryId')) {
            toast.error(`Equipment Category is required. Please enter a value.`);
          } else if (error.includes('SKNNumber')) {
            toast.error(`SKN Number is required. Please enter a value.`);
          } else if (error.includes('Engraved')) {
            toast.error(`Engraved Please enter a value.`);
          } else if (error.includes('CertificateNumber')) {
            toast.error(`Certificate Number is required. Please select a value.`);
          } else if (error.includes('PONumber')) {
            toast.error(`PO Number is required. Please select a value.`);
          } else if (error.includes('PurchaseDate')) {
            toast.error(`Purchase Date is required. Please select a value.`);
          } else if (error.includes('PurchaseValue')) {
            toast.error(`Purchase Value is required. Please select a value.`);
          } else if (error.includes('Conformity')) {
            toast.error(`Conformity is required. Please select a value.`);
          } else if (error.includes('FirstInspectionDate')) {
            toast.error(`First Inspection Date is required. Please select a value.`);
          } else if (error.includes('LastInspectionDate')) {
            //   toast.error(`Last Inspection Date is required. Please select a value.`);
            // } else if (error.includes('NextInspectionDate')) {
            //   toast.error(`Next Inspection Date is required. Please select a value.`);
          } else if (error.includes('Status')) {
            toast.error(`Status is required. Please select a value.`);
          }
          //  else if (error.includes('EquipmentDescription')) {
          //   toast.error(`Equipment Description is required. Please select a value.`);
          // }
          else {
            // Display other error messages as they are
            toast.error(`${error}`);
          }
        });
      });
    } else {
      if (typeof editData?.EquipmentId !== 'undefined') {
        formData.EquipmentId = editData.EquipmentId;
      }

      try {
        const response = await axios.post(`${ApiUrl2}/api/Equipment/InsertSingleEquipmentData`, formData);
        handleClose();
        if (response.status === 200) {
          toast.success(`Equipment ${editData !== null ? 'Edit' : 'Added'} Successfully`);
          const parseData = JSON.parse(response.data);
          if (typeof editData?.EquipmentId !== 'undefined') {
            const dateString = formData.PurchaseDate;
            const dateObj = new Date(dateString);
            const formattedDate = dateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });

            formData.PurchaseDate = formattedDate;

            dispatch({
              type: addUpdatedEquipment,
              payload: { ...parseData, PONumber: poNumber?.PONumber, SupplierDescription: supplierDescription },
            });
            fetchOrderData();
          } else {
            dispatch({
              type: addEquipmentData,
              payload: { ...parseData, PONumber: poNumber?.PONumber, SupplierDescription: supplierDescription },
            });
            fetchOrderData();
          }
        } else {
          toast.success(`Equipment ${editData !== null ? 'Edit' : 'Added'} Successfully`);
        }
      } catch (e) {
        handleClose()
        toast.error(`${JSON.parse(e.request.response).Message}`);
        console.log(JSON.parse(e.request.response).Message);
      }
    }
  };

  useEffect(() => {
    if (equipmentIdData) {
      const findPO = purchaseOrderData?.find(
        (item) => item?.PONumber == equipmentIdData?.PONumber
      );

      const equipments = findPO?.EquipmentCategoryAndQuantityList || [];

      const equipment = equipments?.find(
        (data) => data.EquipmentCategoryId === equipmentIdData.EquipmentCategoryId
      )

      setEquipmentCategoryData(equipments);
      const purchaseDate = dayjs(equipmentIdData?.PurchaseDate);
      const firstInsDate = dayjs(equipmentIdData?.FirstInspectionDate);
      const lastInsDate = dayjs(equipmentIdData?.LastInspectionDate);
      const nextInsDate = dayjs(equipmentIdData?.NextInspectionDate);
      setEquipmentCategory2(equipment);
      setSKNNumber(equipmentIdData?.SKNNumber);
      setSupplierName(findPO?.SupplierName)
      setSupplierDescription(equipment?.SupplierDescription)
      setSupplierCode(equipment?.SupplierCode)
      setPurchaseDate(purchaseDate);
      setFirstIspectionDate(firstInsDate);
      setLastInspectionDate(lastInsDate);
      setNextInspectionDate(nextInsDate);
      setManufacturerNumber(equipmentIdData?.ManufacturerNumber);
      setCertificateNumber(equipmentIdData?.CertificateNumber);
      setPoNumber(findPO);
      setPurchaseValue(equipmentIdData?.PurchaseValue);
      setConformity(equipmentIdData?.Conformity);
      // setNewConformityOption('')
      // setConformityList(equipmentIdData?.Conformity)

      const selectedStatus = equipmentIdData?.Status?.toLowerCase();
      const filteredStatus = statusData.find(
        (status) => status?.toLowerCase() === selectedStatus
      );
      setStatus(filteredStatus);

      setNcrNumber(equipmentIdData?.NCRNumber);
      // setequipmentdescription(equipmentIdData?.EquipmentDescription);
    } else {
      const currentDate = new Date();
      const purchase = dayjs(currentDate)
      setPurchaseDate(purchase);
      setEquipmentCategory2('');
      setSKNNumber('');
      setManufacturerNumber('');
      setCertificateNumber('');
      setSupplierName('')
      setSupplierDescription('')
      setSupplierCode('')
      setPoNumber('');
      // setPurchaseDate(dayjs(''));
      setPurchaseValue('');
      setConformity([]);
      // setNewConformityOption('')
      // setConformityList([])
      setFirstIspectionDate('');
      setLastInspectionDate('');
      setNextInspectionDate('');
      setStatus('SP');
      setNcrNumber('');
      // setequipmentdescription('');
    }
  }, [equipmentIdData]);

  useEffect(() => {
    if (equipmentCategory2 !== '') {
        const name = newEquipmentCategory2?.SerialInitials;
        const generatedEquipment = newEquipmentCategory2?.EquipmentCount;

        // Extract the numeric part from FirstGeneratedSKN
        const sknMatch = newEquipmentCategory2?.FirstGeneratedSKN?.match(/\d+/);
        const sknNumber = sknMatch ? parseInt(sknMatch[0], 10) : 0;

        // Calculate the total number
        const totalNumber = (generatedEquipment || 0) + sknNumber;

        // Ensure the number is always 4 digits
        const number = totalNumber === 0 
            ? "0000" 
            : (totalNumber).toString().padStart(4, '0');

        // Concatenate the name and number
        const sknName = name ? name + number : '';
        console.log("sknName", sknName)
        setSKNNumber(sknName);
    }
}, [newEquipmentCategory2]);


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{ marginBottom: 2 }} variant="h4">{editData !== null ? 'Edit' : 'Add New'} Equipment</Typography>
          {/* <InputField1
            id="PurchaseValue"
            name="PurchaseValue"
            type="number"
            fullWidth
            autoComplete={'on'}
            label="Purchase Value"
            value={purchaseValue}
            onChange={(e) => setPurchaseValue(e.target.value)}
            margin="normal"
          /> */}
          <CustomSelect
            id="PONumber"
            name="PONumber"
            fullWidth
            autoComplete="on"
            label="PO Number"
            value={poNumber}
            disabled={(equipmentIdData || editData?.ManifestName || editData?.Status == "Deleted") ? true : false}
            onChange={(e) => {
              const selectedOrder = e.target.value;
              console.log("selectedOrder ", selectedOrder )
              setPoNumber(selectedOrder);
              const equipments = selectedOrder?.EquipmentCategoryAndQuantityList || [];
        
              const equipmentList = [...equipments].sort((a, b) => 
                a.EquipmentCategoryName.localeCompare(b.EquipmentCategoryName)
              );
              
              setEquipmentCategoryData(equipmentList);
              setSupplierName('');
              setSKNNumber('')
              setSupplierDescription('');
              setSupplierCode('');
            }}
            menus={
              purchaseOrderData
                ? purchaseOrderData.map((order, i) => (
                  order?.Status === "Completed"
                    ?
                    <MenuItem key={i} disabled={true} value={order}>
                      {`${order?.PONumber} (Completed)`}
                    </MenuItem>
                    :
                    (order?.Status === "Deleted"
                      ?
                      <MenuItem disabled={true} key={i} value={order}>
                        {`${order?.PONumber} (Deleted)`}
                      </MenuItem>
                      :
                      <MenuItem key={i} value={order}>
                        {order?.PONumber}
                      </MenuItem>
                    )
                ))
                // ? purchaseOrderData.map((order, i) => (
                //   <MenuItem key={i} value={order}>
                //     {order?.PONumber}
                //   </MenuItem>
                // ))
                : (
                  <MenuItem disabled>No data available</MenuItem>
                )
            }
          />
          {`  `}
          <CustomSelect
            id="EquipmentCategory"
            name="EquipmentCategory"
            margin="normal"
            label="Equipment Category"
            autoComplete={'on'}
            disabled={(equipmentIdData || editData?.ManifestName || editData?.Status == "Deleted") ? true : false}
            value={equipmentCategory2}
            onChange={(e) => {
              setEquipmentCategory2(e.target.value)
              setNewEquipmentCategory(e.target.value)
              setSupplierName(poNumber?.SupplierName)
              setSupplierDescription(e.target.value?.SupplierDescription)
              setSupplierCode(e.target.value?.SupplierCode)
              setConformity(e.target.value?.Conformity)
            }}
            menus={
              equipmentCategoryData
                ? equipmentCategoryData.map((category, i) => (
                  <MenuItem key={i} value={category}>
                    {`${category?.EquipmentCategoryName} ( ${category?.SupplierDescription} )`}
                  </MenuItem>
                ))
                : (
                  <MenuItem disabled>No data available</MenuItem>
                )
            }
          />
          <TableContainer>
            <Table sx={{ border: '1px solid #ddd', mb: 1, mt: 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Supplier Description</TableCell>
                  <TableCell>Supplier code</TableCell>
                </TableRow>
              </TableHead>
              {supplierName !== '' &&
                <TableBody>
                  <TableRow>
                    <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                      {supplierName || ''}
                    </TableCell>
                    <TableCell style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden' }}>
                      {supplierDescription || ''}
                    </TableCell>
                    <TableCell style={{ minWidth: '100px', maxWidth: '100px', overflow: 'hidden' }}>
                      {supplierCode || ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              }
            </Table>
          </TableContainer>
          <InputField1
            id="SKNNumber"
            name="SKNNumber"
            fullWidth
            label="SKN Number"
            autoComplete={'on'}
            value={sknNumber}
            disabled={true}
            onChange={(e) => setSKNNumber(e.target.value)}
            margin="normal"
          />
          <InputField1
            id="EngravedNumber"
            name="EngravedNumber"
            fullWidth
            label="Engraved Number"
            autoComplete={'on'}
            value={sknNumber}
            disabled={true}
            onChange={(e) => setSKNNumber(e.target.value)}
            margin="normal"
          />
          {/* <CustomSelect
            margin="normal"
            label="Engraved"
            value={engraved?.value}
            onChange={(e) => setEngraved(e.target.value)}
            menus={DropdownEngraved.map((category, i) => (
              <MenuItem key={i} value={category?.value}>
                {category?.value}
              </MenuItem>
            ))}
          /> */}
          <InputField1
            id="ManufacturerNumber"
            name="ManufacturerNumber"
            fullWidth
            autoComplete={'on'}
            label="Manufacturer Number"
            disabled={equipmentIdData? true: false}
            value={manufacturerNumber}
            onChange={(e) => setManufacturerNumber(e.target.value)}
            margin="normal"
          />
          <InputField1
            id="CertificateNumber"
            name="CertificateNumber"
            fullWidth
            autoComplete={'on'}
            label="Certificate Number"
            disabled={(editData?.ManifestName || editData?.Status == "Deleted") ? true : false}
            value={certificateNumber}
            onChange={(e) => setCertificateNumber(e.target.value)}
            margin="normal"
          />
          <InputField1
            id="PurchaseValue"
            name="PurchaseValue"
            fullWidth
            margin="normal"
            type="number"
            autoComplete={'on'}
            label="Purchase Value"
            disabled={(editData?.ManifestName || editData?.Status == "Deleted") ? true : false}
            value={purchaseValue}
            onChange={(e) => setPurchaseValue(e.target.value)}
          />
          <CustomDate
            id="PurchaseDate"
            name="PurchaseDate"
            fullWidth
            style={{ marginTop: "16px", marginBottom:"6px" }}
            autoComplete={'on'}
            label="Purchase Date"
            value={purchaseDate}
            disabled={(editData?.ManifestName || editData?.Status == "Deleted") ? true : false}
            onChange={(e) => setPurchaseDate(e)}
            margin="normal"
          />
          <InputField1
            id='Conformity'
            name='Conformity'
            fullWidth
            margin="normal"
            autoComplete={'on'}
            label='Conformity'
            disabled={true }
            value={conformity}
          />
          {/* <Box>
            <CustomDate
              id="FirstInspDate"
              name="FirstInspDate"
              fullWidth
              autoComplete={'on'}
              label="First Inspection Date"
              value={firstInspectionDate}
              onChange={(e) => setFirstIspectionDate(e)}
              margin="normal"
            />
          </Box> */}
          {/* <Box className="my-3">
            <CustomDate
             id="LastInspDate"
             name="LastInspDate"
              fullWidth
              label="Last Inspection Date"
              value={lastInspectionDate}
              onChange={(e) => setLastInspectionDate(e)}
              margin="normal"
            />
          </Box>
          <Box>
            <CustomDate
            id="NextInspDate"
            name="NextInspDate"
              fullWidth
              label="Next Inspection Date"
              value={nextInspectionDate}
              onChange={(e) => setNextInspectionDate(e)}
              margin="normal"
            />
          </Box> */}
          {/* <InputField1
            fullWidth
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            margin="normal"
          /> */}
          <CustomSelect
            id="Status"
            name="Status"
            margin="normal"
            label="Status"
            disabled={true}
            autoComplete={'on'}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            menus={
              statusData?.length > 0 ? (
                statusData.map((data, i) => (
                  <MenuItem key={i} value={data}>
                    {data}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No data available</MenuItem>
              )
            }
          />
          {/* <InputField1
            fullWidth
            label="NCR Number"
            value={ncrNumber}
            onChange={(e) => setNcrNumber(e.target.value)}
            margin="normal"
          /> */}
          {/* <InputField1
            id="EquipmentDesc"
            name="EquipmentDesc"
            fullWidth
            autoComplete={'on'}
            label="Equipment Description"
            value={equipmentdescription}
            onChange={(e) => setequipmentdescription(e.target.value)}
            margin="normal"
          /> */}

          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
