import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getDatePasseddata, getnextweekInspectiondata } from 'src/redux/dashBoardSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchDashboardData = () => {
  const datePassedDatas = useSelector((state) => state.dashboard.datePassedData);
  const inspectionNextWeekDatas = useSelector((state) => state.dashboard.nextweekInspection);
  const dispatch = useDispatch();

  const fetchdatePassData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/InspectionDatePassed`);
      if (response.status === 200) {

        dispatch({
          type: getDatePasseddata,
          payload: JSON.parse(response.data).map((item, i) => {
            const dateString = item.NextInspectionDate;
            const dateObj = new Date(dateString);
            const formattedDate = dateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });

            item.NextInspectionDate = formattedDate;
            return { ...item, id: i };
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchNextWeekInspectionData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/InspectionDateNextWeek`);
      if (response.status === 200) {
        dispatch({
          type: getnextweekInspectiondata,
          payload: JSON.parse(response.data).map((item, i) => {
            const dateString = item.NextInspectionDate;
            const dateObj = new Date(dateString);
            const formattedDate = dateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });

            item.NextInspectionDate = formattedDate;
            return { ...item, id: i };
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { inspectionNextWeekDatas, datePassedDatas, fetchdatePassData, fetchNextWeekInspectionData };
};
